import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useBlocker } from '@remix-run/react';
import type { BlockerFunction } from '@remix-run/router';

type DialogProviderContext = {
  dialogs: Map<
    any,
    {
      blockFunc: () => boolean;
      setIsOpen: (isOpen: boolean) => void;
    }
  >;
  registerDialog: (
    key: any,
    blockFunc: () => boolean,
    setIsOpen: (isOpen: boolean) => void,
  ) => void;
  unregisterDialog: (key: any) => void;
};

const initState: DialogProviderContext = {
  dialogs: new Map(),
  registerDialog: () => {},
  unregisterDialog: () => {},
};

export const DialogContext = createContext<DialogProviderContext>(initState);

export const useCloseDialogOnBack = (
  key: any,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
) => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('Hook must be used inside DialogProvider');
  }

  useEffect(() => {
    if (!key) {
      return;
    }

    context.registerDialog(
      key,
      () => isOpen,
      (value) => setIsOpen(value),
    );

    return () => {
      if (key) {
        context.unregisterDialog(key);
      }
    };
  }, [key, isOpen]);
};

const DialogProvider: React.FC<{ children: any }> = ({
  children,
}: {
  children: any;
}) => {
  const [context, setContext] = useState<DialogProviderContext>(initState);

  let shouldBlock = useCallback<BlockerFunction>(() => {
    let result = false;
    context.dialogs.forEach((dialog) => {
      result = result || dialog.blockFunc();
    });
    return result;
  }, [context]);

  const blocker = useBlocker(shouldBlock);
  useEffect(() => {
    if (blocker.state === 'blocked') {
      context.dialogs.forEach((dialog) => {
        dialog.setIsOpen(false);
      });
      blocker.reset();
    }
  }, [blocker.state]);

  const value: DialogProviderContext = {
    ...context,
    registerDialog: (key, blockFunc, setIsOpen) =>
      setContext((prev: DialogProviderContext) => {
        const newDialogs = new Map(prev.dialogs);
        newDialogs.set(key, { blockFunc, setIsOpen });
        return {
          ...prev,
          dialogs: newDialogs,
        };
      }),
    unregisterDialog: (key) =>
      setContext((prev: DialogProviderContext) => {
        const newDialogs = new Map(prev.dialogs);
        newDialogs.delete(key);
        return {
          ...prev,
          dialogs: newDialogs,
        };
      }),
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};

export default DialogProvider;
